/* body{
    margin: 0;
    background-color: #404040;
    color:#c5aa6a;
}
.intro{
    height: 650px;
}
a {
    color: white;
    text-decoration: none;
  }
  
  a:hover {
      color:whitesmoke
  }
  
  a:active {
      color: white;
  }
  
  a:visited {
      color: white;
  } */

  /*

/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */

body { background: #0f0f0f; }

/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

header {
   position: relative;
   height: 800px;
   min-height: 500px;
   width: 100%;
   background: #161415 url(../public/images/smoke.jpeg) no-repeat top center;
   background-size: cover !important;
	-webkit-background-size: cover !important;
   text-align: center;
   overflow: hidden;
}

/* vertically center banner section */
header:before {
   content: '';
   display: inline-block;
   vertical-align: middle;
   height: 100%;
}
header .banner {
   display: inline-block;
   vertical-align: middle;
   margin: 0 auto;
   width: 85%;
   padding-bottom: 30px;
   text-align: center;
}

header .banner-text { width: 100%; }
header .banner-text h1 {
   font: 90px/1.1em 'opensans-bold', sans-serif;
   color: #fff;
   letter-spacing: -2px;
   margin: 0 auto 18px auto;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
}
header .banner-text h3 {
   font: 18px/1.9em 'librebaskerville-regular', serif;
   color: #A8A8A8;
   margin: 0 auto;
   width: 70%;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
}
header .banner-text h3 span,
header .banner-text h3 a {
   color: #fff;
}
header .banner-text hr {
   width: 60%;
   margin: 18px auto 24px auto;
   border-color: #2F2D2E;
   border-color: rgba(150, 150, 150, .1);
}

/* header social links */
header .social {
   margin: 24px 0;
   padding: 0;
   font-size: 30px;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}
header .social li {
   display: inline-block;
   margin: 0 15px;
   padding: 0;
}
header .social li a { color: #fff; }
header .social li a:hover { color: #11ABB0; }

/* scrolldown link */
header .scrolldown a {
   position: absolute;
   bottom: 30px;
   left: 50%;
   margin-left: -29px;
   color: #fff;
   display: block;
   height: 42px;
   width: 42px;
   font-size: 42px;
   line-height: 42px;
   color: #fff;
   border-radius: 100%;

   -webkit-transition: all .3s ease-in-out;
   -moz-transition: all .3s ease-in-out;
   -o-transition: all .3s ease-in-out;
   transition: all .3s ease-in-out;
}
header .scrolldown a:hover { color: #11ABB0; }

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap ul, #nav-wrap li, #nav-wrap a {
	 margin: 0;
	 padding: 0;
	 border: none;
	 outline: none;
}

/* nav-wrap */
#nav-wrap {
   font: 12px 'opensans-bold', sans-serif;
   width: 100%;
   text-transform: uppercase;
   letter-spacing: 2.5px;
   margin: 0 auto;
   z-index: 100;
   position: fixed;
   left: 0;
   top: 0;
}
.opaque { background-color: #333; }

/* hide toggle button */
#nav-wrap > a.mobile-btn { display: none; }

ul#nav {
   min-height: 48px;
   width: auto;

   /* center align the menu */
   text-align: center;
}
ul#nav li {
   position: relative;
   list-style: none;
   height: 48px;
   display: inline-block;
}

/* Links */
ul#nav li a {

/* 8px padding top + 8px padding bottom + 32px line-height = 48px */

   display: inline-block;
   padding: 8px 13px;
   line-height: 32px;
	text-decoration: none;
   text-align: left;
   color: #fff;

	-webkit-transition: color .2s ease-in-out;
	-moz-transition: color .2s ease-in-out;
	-o-transition: color .2s ease-in-out;
	-ms-transition: color .2s ease-in-out;
	transition: color .2s ease-in-out;
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a { color: #F06000; }


/* ------------------------------------------------------------------ */
/* c. About Section
/* ------------------------------------------------------------------ */

#about {
   background: #2B2B2B;
   padding-top: 96px;
   padding-bottom: 66px;
   overflow: hidden;
}

#about a, #about a:visited  { color: #fff; }
#about a:hover, #about a:focus { color: #11ABB0; }

#about h2 {
   font: 22px/30px 'opensans-bold', sans-serif;
   color: #fff;
   margin-bottom: 12px;
}
#about p {
   line-height: 30px;
   color: #7A7A7A;
}
#about .profile-pic {
   position: relative;
   width: 120px;
   height: 120px;
   border-radius: 100%;
}
#about .contact-details { width: 41.66667%; }
#about .download {
   width: 58.33333%;
   padding-top: 6px;
}
#about .main-col { padding-right: 5%; }
#about .download .button {
   margin-top: 6px;
   background: #444;
}
#about .download .button:hover {
   background: #fff;
   color: #2B2B2B;
}
#about .download .button i {
   margin-right: 15px;
   font-size: 20px;
}


/* ------------------------------------------------------------------ */
/* d. Resume Section
/* ------------------------------------------------------------------ */

#resume {
   background: #fff;
   padding-top: 90px;
   padding-bottom: 72px;
   overflow: hidden;
}

#resume a, #resume a:visited  { color: #11ABB0; }
#resume a:hover, #resume a:focus { color: #313131; }

#resume h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
}
#resume h1 span {
   border-bottom: 3px solid #11ABB0;
   padding-bottom: 6px;
}
#resume h3 {
   font: 25px/30px 'opensans-bold', sans-serif;
}

#resume .header-col { padding-top: 9px; }
#resume .main-col { padding-right: 10%; }

.education, .work {
   margin-bottom: 48px;
   padding-bottom: 24px;
   border-bottom: 1px solid #E8E8E8;
}
#resume .info {
   font: 16px/24px 'librebaskerville-italic', serif;
   color: #6E7881;
   margin-bottom: 18px;
   margin-top: 9px;
}
#resume .info span {
   margin-right: 5px;
   margin-left: 5px;
}
#resume .date {
   font: 15px/24px 'opensans-regular', sans-serif;
   margin-top: 6px;
}

/*----------------------------------------------*/
/*	Skill Bars
/*----------------------------------------------*/

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}
.bars .skills {
  	margin-top: 36px;
   list-style: none;
}
.bars li {
   position: relative;
  	margin-bottom: 60px;
  	background: #ccc;
  	height: 42px;
  	border-radius: 3px;
}
.bars li em {
	font: 15px 'opensans-bold', sans-serif;
   color: #313131;
	text-transform: uppercase;
   letter-spacing: 2px;
	font-weight: normal;
   position: relative;
	top: -36px;
}
.bar-expand {
   position: absolute;
   left: 0;
   top: 0;

   margin: 0;
   padding-right: 24px;
  	background: #313131;
   display: inline-block;
  	height: 42px;
   line-height: 42px;
   border-radius: 3px 0 0 3px;
}
/*
.git {
  	-moz-animation: git 2s ease;
  	-webkit-animation: git 2s ease;
}
.illustrator {
  	-moz-animation: illustrator 2s ease;
  	-webkit-animation: illustrator 2s ease;
}
.reactjs {
  	-moz-animation: reactjs 2s ease;
  	-webkit-animation: reactjs 2s ease;
}
.css {
  	-moz-animation: css 2s ease;
  	-webkit-animation: css 2s ease;
}
.html5 {
  	-moz-animation: html5 2s ease;
  	-webkit-animation: html5 2s ease;
}
.vuejs {
  	-moz-animation: vuejs 2s ease;
  	-webkit-animation: vuejs 2s ease;
}
.mongodb {
  	-moz-animation: mongodb 2s ease;
  	-webkit-animation: mongodb 2s ease;
}

@-moz-keyframes git {
  0%   { width: 0px;  }
  100% { width: 60%;  }
}
@-moz-keyframes illustrator {
  0%   { width: 0px;  }
  100% { width: 55%;  }
}
@-moz-keyframes reactjs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-moz-keyframes css {
  0%   { width: 0px;  }
  100% { width: 90%;  }
}
@-moz-keyframes html5 {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}
@-moz-keyframes vuejs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-moz-keyframes mongodb {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}

@-webkit-keyframes photoshop {
  0%   { width: 0px;  }
  100% { width: 60%;  }
}
@-webkit-keyframes illustrator {
  0%   { width: 0px;  }
  100% { width: 55%;  }
}
@-webkit-keyframes reactjs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-webkit-keyframes css {
  0%   { width: 0px;  }
  100% { width: 90%;  }
}
@-webkit-keyframes html5 {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}
@-webkit-keyframes vuejs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-webkit-keyframes mongodb {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}  */

/* ------------------------------------------------------------------ */
/* e. Portfolio Section
/* ------------------------------------------------------------------ */

#portfolio {
   background: #ebeeee;
   padding-top: 90px;
   padding-bottom: 60px;
}
#portfolio h1 {
   font: 15px/24px 'opensans-semibold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-align: center;
   margin-bottom: 48px;
   color: #95A3A3;
}

/* Portfolio Content */
#portfolio-wrapper .columns { margin-bottom: 36px; }
.portfolio-item .item-wrap {
   background: #fff;
   overflow: hidden;
   position: relative;

   -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.portfolio-item .item-wrap a {
   display: block;
   cursor: pointer;
}

/* overlay */
.portfolio-item .item-wrap .overlay {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;

	opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);

   -webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;

   background: url(./images/overlay-bg.png) repeat;
}
.portfolio-item .item-wrap .link-icon {
   display: block;
   color: #fff;
   height: 30px;
   width: 30px;
   font-size: 18px;
   line-height: 30px;
   text-align: center;

   opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);

   -webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;

   position: absolute;
   top: 50%;
   left: 50%;
   margin-left: -15px;
   margin-top: -15px;
}
.portfolio-item .item-wrap img {
   vertical-align: bottom;
}
.portfolio-item .portfolio-item-meta { padding: 18px }
.portfolio-item .portfolio-item-meta h5 {
   font: 14px/21px 'opensans-bold', sans-serif;
   color: #fff;
}
.portfolio-item .portfolio-item-meta p {
   font: 12px/18px 'opensans-light', sans-serif;
   color: #c6c7c7;
   margin-bottom: 0;
}

/* on hover */
.portfolio-item:hover .overlay {
	opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}
.portfolio-item:hover .link-icon {
   opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}

/* popup modal */
.popup-modal {
	max-width: 550px;
	background: #fff;
	position: relative;
	margin: 0 auto;
}
.popup-modal .description-box { padding: 12px 36px 18px 36px; }
.popup-modal .description-box h4 {
   font: 15px/24px 'opensans-bold', sans-serif;
	margin-bottom: 12px;
   color: #111;
}
.popup-modal .description-box p {
	font: 14px/24px 'opensans-regular', sans-serif;
   color: #A1A1A1;
   margin-bottom: 12px;
}
.popup-modal .description-box .categories {
   font: 11px/21px 'opensans-light', sans-serif;
   color: #A1A1A1;
   text-transform: uppercase;
   letter-spacing: 2px;
   display: block;
   text-align: left;
}
.popup-modal .description-box .categories i {
   margin-right: 8px;
}
.popup-modal .link-box {
   padding: 18px 36px;
   background: #111;
   text-align: left;
}
.popup-modal .link-box a {
   color: #fff;
	font: 11px/21px 'opensans-bold', sans-serif;
	text-transform: uppercase;
   letter-spacing: 3px;
   cursor: pointer;
}
.popup-modal a:hover {	color: #00CCCC; }
.popup-modal a.popup-modal-dismiss { margin-left: 24px; }


/* fadein/fadeout effect for modal popup
/* ------------------------------------------------------------------ */

/* content at start */
.mfp-fade.mfp-wrap .mfp-content .popup-modal {
   opacity: 0;
   -webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
   transition: all 200ms ease-in-out;
}
/* content fadein */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
   opacity: 1;
}
/* content fadeout */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
   opacity: 0;
}

/* ------------------------------------------------------------------ */
/* f. Call To Action Section
/* ------------------------------------------------------------------ */

#call-to-action {
   background: #212121;
   padding-top: 66px;
   padding-bottom: 48px;
}
#call-to-action h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}
#call-to-action h1 span { display: none; }
#call-to-action .header-col h1:before {
   font-family: 'FontAwesome';
   content: "\f0ac";
	padding-right: 10px;
	font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}
#call-to-action .action {
   margin-top: 12px;
}
#call-to-action h2 {
   font: 28px/36px 'opensans-bold', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}
#call-to-action h2 a {
   color: inherit;
}
#call-to-action p {
   color: #636363;
   font-size: 17px;
}
/*#
call-to-action .button {
	color:#fff;
   background: #0D0D0D;
}
*/
#call-to-action .button:hover,
#call-to-action .button:active {
   background: #FFFFFF;
   color: #0D0D0D;
}
#call-to-action p span {
	font-family: 'opensans-semibold', sans-serif;
	color: #D8D8D8;
}

/* ------------------------------------------------------------------
/* g. Testimonials
/* ------------------------------------------------------------------ */

/* Flex Slider
/* ------------------------------------------------------------------ */

/* Reset */
.flexslider a:active,
.flexslider a:focus  { outline: none; }
.slides,
.flex-control-nav,
.flex-direction-nav { margin: 0; padding: 0; list-style: none; }
.slides li { margin: 0; padding: 0;}

/* Necessary Styles */
.flexslider {
   position: relative;
   zoom: 1;
   margin: 0;
   padding: 0;
}
.flexslider .slides { zoom: 1; }
.flexslider .slides > li { position: relative; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li { display: none; -webkit-backface-visibility: hidden; }
/* Suggested container for slide animation setups. Can replace this with your own */
.flex-container { zoom: 1; position: relative; }

/* Clearfix for .slides */
.slides:before,
.slides:after {
   content: " ";
   display: table;
}
.slides:after {
   clear: both;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child { display: block; }

/* Slider Styles */
.slides { zoom: 1; }
.slides > li {
   /*margin-right: 5px; */
   overflow: hidden;
}

/* Control Nav */
.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -20px;
    text-align: left;
}
.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}
.flex-control-paging li a {
    width: 12px;
    height: 12px;
    display: block;
    background: #ddd;
    background: rgba(255, 255, 255, .3);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    box-shadow: inset 0 0 3px rgba(255, 255, 255, .3);
}
.flex-control-paging li a:hover {
    background: #CCC;
    background: rgba(255, 255, 255, .7);
}
.flex-control-paging li a.flex-active {
    background: #fff;
    background: rgba(255, 255, 255, .9);
    cursor: default;
}

/* ------------------------------------------------------------------ */
/* h. Contact Section
/* ------------------------------------------------------------------ */

#contact {
   background: #191919;
   padding-top: 96px;
   padding-bottom: 102px;
   color: #636363;
}
#contact .section-head { margin-bottom: 42px; }

#contact a, #contact a:visited  { color: #11ABB0; }
#contact a:hover, #contact a:focus { color: #fff; }

#contact h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #EBEEEE;
   margin-bottom: 6px;
}
#contact h1 span { display: none; }
#contact h1:before {
   font-family: 'FontAwesome';
   content: "\f0e0";
	padding-right: 10px;
	font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #ebeeee;
}

#contact h4 {
   font: 16px/24px 'opensans-bold', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}
#contact p.lead {
   font: 18px/36px 'opensans-light', sans-serif;
   padding-right: 3%;
}
#contact .header-col { padding-top: 6px; }


/* contact form */
#contact form { margin-bottom: 30px; }
#contact label {
   font: 15px/24px 'opensans-bold', sans-serif;
   margin: 12px 0;
   color: #EBEEEE;
	display: inline-block;
	float: left;
   width: 26%;
}
#contact input,
#contact textarea,
#contact select {
   padding: 18px 20px;
	color: #eee;
	background: #373233;
	margin-bottom: 42px;
	border: 0;
	outline: none;
   font-size: 15px;
   line-height: 24px;
   width: 65%;
}
#contact input:focus,
#contact textarea:focus,
#contact select:focus {
	color: #fff;
	background-color: #11ABB0;
}
#contact button.submit {
	text-transform: uppercase;
	letter-spacing: 3px;
	color:#fff;
   background: #0D0D0D;
	border: none;
   cursor: pointer;
   height: auto;
   display: inline-block;
	border-radius: 3px;
   margin-left: 26%;
}
#contact button.submit:hover {
	color: #0D0D0D;
	background: #fff;
}
#contact span.required {
	color: #11ABB0;
	font-size: 13px;
}
#message-warning, #message-success {
   display: none;
	background: #0F0F0F;
	padding: 24px 24px;
	margin-bottom: 36px;
   width: 65%;
   margin-left: 26%;
}
#message-warning { color: #D72828; }
#message-success { color: #11ABB0; }

#message-warning i,
#message-success i {
   margin-right: 10px;
}
#image-loader {
   display: none;
   position: relative;
   left: 18px;
   top: 17px;
}


/* Twitter Feed */
#twitter {
   margin-top: 12px;
   padding: 0;
}
#twitter li {
   margin: 6px 0px 12px 0;
   line-height: 30px;
}
#twitter li span {
   display: block;
}
#twitter li b a {
   font: 13px/36px 'opensans-regular', Sans-serif;
   color: #474747 !important;
   border: none;
}


/* ------------------------------------------------------------------ */
/* i. Footer
/* ------------------------------------------------------------------ */

footer {
   padding-top: 48px;
   margin-bottom: 48px;
   color: #303030;
   font-size: 14px;
   text-align: center;
   position: relative;
}

footer a, footer a:visited { color: #525252; }
footer a:hover, footer a:focus { color: #fff; }

/* copyright */
footer .copyright {
    margin: 0;
    padding: 0;
 }
footer .copyright li {
    display: inline-block;
    margin: 0;
    padding: 0;
    line-height: 24px;
}
.ie footer .copyright li {
   display: inline;
}
footer .copyright li:before {
    content: "\2022";
    padding-left: 10px;
    padding-right: 10px;
    color: #095153;
}
footer .copyright  li:first-child:before {
    display: none;
}

/* social links */
footer .social-links {
   margin: 18px 0 30px 0;
   padding: 0;
   font-size: 30px;
}
footer .social-links li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 42px;
    color: #F06000;
}

footer .social-links li:first-child { margin-left: 0; }

/* Go To Top Button */
#go-top {
	position: absolute;
	top: -24px;
   left: 50%;
   margin-left: -30px;
}
#go-top a {
	text-decoration: none;
	border: 0 none;
	display: block;
	width: 60px;
	height: 60px;
	background-color: #525252;

	-webkit-transition: all 0.2s ease-in-out;
   -moz-transition: all 0.2s ease-in-out;
   -o-transition: all 0.2s ease-in-out;
   -ms-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;

   color: #fff;
   font-size: 21px;
   line-height: 60px;
 	border-radius: 100%;
}
#go-top a:hover { background-color: #0F9095; }


/* ==================================================================

*   Ceevee Media Queries
*   url: styleshout.com
*   03-18-2014

/* ================================================================== */


/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {

    /* header styles
   ------------------------------------------------------------------ */
   header .banner-text h1 {
      font: 80px/1.1em 'opensans-bold', sans-serif;
      letter-spacing: -1px;
      margin: 0 auto 12px auto;
   }

}

/* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {

    /* header styles
   ------------------------------------------------------------------ */
   header .banner { padding-bottom: 12px; }
   header .banner-text h1 {
      font: 78px/1.1em 'opensans-bold', sans-serif;
      letter-spacing: -1px;      
   }
   header .banner-text h3 {
      font: 17px/1.9em 'librebaskerville-regular', serif;
      width: 80%;
   }
   header .banner-text hr {
      width: 65%;
      margin: 12px auto;
   }
   /* nav-wrap */
   #nav-wrap {
      font: 11px 'opensans-bold', sans-serif;
      letter-spacing: 1.5px;
   }


   /* About Section
   ------------------------------------------------------------------- */
   #about .profile-pic {
      width: 114px;
      height: 114px;
      margin-left: 12px;
   }
   #about .contact-details { width: 50%; }
   #about .download { width: 50%; }

   /* Resume Section
   ------------------------------------------------------------------- */
   #resume h1 { font: 16px/24px 'opensans-bold', sans-serif; }
   #resume .main-col { padding-right: 5%; }   

   /* Testimonials Section
   ------------------------------------------------------------------- */
   #testimonials .header-col h1:before {
      font-size: 66px;
      line-height: 66px;
   }
   #testimonials blockquote p {
      font-size: 22px;
      line-height: 46px;      
   }

    /* Call to Action Section
   ------------------------------------------------------------------- */
   #call-to-action .header-col h1:before {
      font-size: 66px;
      line-height: 66px;
   }

   /* Contact Section
   ------------------------------------------------------------------- */
   #contact .section-head { margin-bottom: 30px; }
   #contact .header-col h1:before {
      font-size: 66px;
      line-height: 66px;
   }
   #contact .section-head p.lead { font: 17px/33px opensans-light, sans-serif; }


}

/* mobile wide/smaller tablets
---------------------------------------------------------------------- */

@media only screen and (max-width: 767px) {

   /* mobile navigation
   -------------------------------------------------------------------- */
   #nav-wrap {
      font: 12px 'opensans-bold', sans-serif;
      background: transparent !important;
      letter-spacing: 1.5px;  
      width: auto;
      position: fixed;
      top: 0;
      right: 0;
   }
   #nav-wrap > a {
	   width: 48px;
		height: 48px;
		text-align: left;
		background-color: #CC5200;
		position: relative;
      border: none;
      float: right;

      font: 0/0 a;
      text-shadow: none;
      color: transparent;

      position: relative;
      top: 0px;
      right: 30px;
   }

	#nav-wrap > a:before,
   #nav-wrap > a:after {
	   position: absolute;
		border: 2px solid #fff;
		top: 35%;
		left: 25%;
		right: 25%;
		content: '';
	}
   #nav-wrap > a:after { top: 60%; }

   /* toggle buttons */
	#nav-wrap:not( :target ) > a:first-of-type,
	#nav-wrap:target > a:last-of-type  {
	   display: block;
	}

   /* hide menu panel */
   #nav-wrap ul#nav {
      height: auto;
		display: none;
      clear: both;
      width: auto; 
      float: right;     

      position: relative;
      top: 12px;
      right: 0;
   }

   /* display menu panels */
	#nav-wrap:target > ul#nav	{
	   display: block;
      padding: 30px 20px 48px 20px;
      background: #1f2024;
      margin: 0 30px;
      clear: both;
   }

   ul#nav li {
      display: block;
      height: auto;      
      margin: 0 auto; 
      padding: 0 4%;           
      text-align: left;
      border-bottom: 1px solid #2D2E34;
      border-bottom-style: dotted; 
   }
  
   ul#nav li a {  
      display: block;    
      margin: 0;
      padding: 0;      
      margin: 12px 0; 
      line-height: 16px; /* reset line-height from 48px */
      border: none;
   }  


   /* Header Styles
   -------------------------------------------------------------------- */
   header .banner {
      padding-bottom: 12px;
      padding-top: 6px;
   }
   header .banner-text h1 { font: 68px/1.1em 'opensans-bold', sans-serif; }
   header .banner-text h3 {
      font: 16px/1.9em 'librebaskerville-regular', serif;
      width: 85%;
   }
   header .banner-text hr {
      width: 80%;
      margin: 18px auto;
   }

   /* header social links */
   header .social {
      margin: 18px 0 24px 0;
      font-size: 24px;
      line-height: 36px;      
   }
   header .social li { margin: 0 10px; }

    /* scrolldown link */
   header .scrolldown { display: none; }


   /* About Section
   -------------------------------------------------------------------- */
   #about .profile-pic { display: none; }
   #about .download .button {
      width: 100%;
      text-align: center;
      padding: 15px 20px;
   }
   #about .main-col { padding-right: 30px; }


   /* Resume Section
   --------------------------------------------------------------------- */
   #resume .header-col {
      padding-top: 0;
      margin-bottom: 48px;
      text-align: center;
   }
   #resume h1 { letter-spacing: 3px; }
   #resume .main-col { padding-right: 30px; }
   #resume h3, #resume .info { text-align: center; }
      
   .bars { width: 100%; }


   /* Call To Action Section
   /* ----------------------------------------------------------------- */
   #call-to-action { text-align: center; }
   #call-to-action h1 {
      font: 16px/24px 'opensans-bold', sans-serif;
      text-align: center;
      margin-bottom: 30px;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
   }
   #call-to-action h1 span { display: block; }
   #call-to-action .header-col h1:before { content: none; }
   #call-to-action p { font-size: 15px; }


   /* Portfolio Section
   /* ----------------------------------------------------------------- */
   #portfolio-wrapper .columns { margin-bottom: 40px; }
   .popup-modal {	max-width: 85%; }


   /* Testimonials Section
   ----------------------------------------------------------------------- */
   #testimonials .text-container { text-align: center; }
   #testimonials h1 {
      font: 16px/24px 'opensans-bold', sans-serif;
      text-align: center;
      margin-bottom: 30px;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
   }
   #testimonials h1 span { display: block; }
   #testimonials .header-col h1:before { content: none; }
   #testimonials blockquote { padding-bottom: 24px; }
   #testimonials blockquote p {
      font-size: 20px;
      line-height: 42px;      
   }

   /* Control Nav */
   .flex-control-nav {
      text-align: center;
      margin-left: -30px;
   }


   /* contact Section
   ----------------------------------------------------------------------- */
   #contact { padding-bottom: 66px; }
   #contact .section-head { margin-bottom: 12px; }
   #contact .section-head h1 {
      font: 16px/24px 'opensans-bold', sans-serif;            
      text-align: center;   
      margin-bottom: 30px;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
   }  
   #contact h1 span { display: block; }
   #contact .header-col { padding-top: 0; }
   #contact .header-col h1:before { content: none;	}
   #contact .section-head p.lead { text-align: center;}

   /* form */
   #contact label {
      float: none;
      width: 100%;
   }
   #contact input,
   #contact textarea,
   #contact select {
     	margin-bottom: 6px;    	
      width: 100%;
   }
   #contact button.submit { margin: 30px 0 24px 0; }
   #message-warning, #message-success {
      width: 100%;
      margin-left: 0;
   }


   /* footer
   ------------------------------------------------------------------------ */
  
   /* copyright */
   footer .copyright li:before { content: none; }
   footer .copyright li { margin-right: 12px; }

   /* social links */
   footer .social-links { font-size: 22px; }
   footer .social-links li { margin-left: 18px; }

   /* Go To Top Button */
   #go-top { margin-left: -22px; }
   #go-top a {
   	width: 54px;
   	height: 54px;
      font-size: 18px;
      line-height: 54px;
   }


}

/* mobile narrow
  -------------------------------------------------------------------------- */

@media only screen and (max-width: 480px) {

   /* mobile navigation
   -------------------------------------------------------------------- */
   #nav-wrap ul#nav { width: auto; float: none; }

   /* header styles
   -------------------------------------------------------------------- */
   header .banner { padding-top: 24px; }
   header .banner-text h1 {
      font: 40px/1.1em 'opensans-bold', sans-serif;      
      margin: 0 auto 24px auto;
   }
   header .banner-text h3 {
      font: 14px/1.9em 'librebaskerville-regular', sans-serif;
      width: 90%;
   }
  
   /* header social links */
   header .social { font-size: 20px;}
   header .social li { margin: 0 6px; }

   /* footer
   ------------------------------------------------------------------------ */

   /* social links */
   footer .social-links { font-size: 20px; }
   footer .social-links li { margin-left: 14px; }   

}








